import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 43",
  week: "Semana 7",
  month: "abr",
  day: "25",
  monthNumber: "04",
  date: "2020-04-25",
  path: "/cronologia/semana-07#dia-25-abr",
};
const Day43 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDataEu fecha={"2020-04-25"} />
        <ModLethalityEu fecha={"2020-04-25"} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En España, el balance del día se salda con el registro de 4.075 nuevos
          positivos y 378 personas fallecidas. Permanecen hospitalizadas 78.516
          personas, 29 menos que el día anterior, y 3.353 reciben el alta. El
          número de casos ingresados en la UCI asciende a 7.756, lo que supone
          una disminución de 24 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 42,8 %.
        </ModText>
        <ModText>
          En total, 10 de las 19 comunidades autónomas han notificado hoy{" "}
          <strong>más casos curados que contagiados</strong>.
        </ModText>
        <ModGraph
          name="25-04-ccaa-recuperadosvsconfirmados"
          alt="Recuperados vs contagiados coronavirus en España por CC.AA."
        />
        <ModTwoCols
          src="/images/svg/25_mar-salida_menores.svg"
          alt="Crisis COVID-19. Menores pueden salir acompañados"
          small={false}
        >
          Hoy se ha publicado en el BOE la orden que regula las condiciones en
          las que deben tener lugar los desplazamientos por parte de la
          población infantil a partir de mañana 26 de abril.
        </ModTwoCols>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/sanidad14/Documents/2020/250420%20Gu%C3%ADa%20de%20buenas%20pr%C3%A1cticas%20en%20las%20salidas%20de%20la%20poblaci%C3%B3n%20infantil%20durante%20el%20estado%20de%20alarma.pdf"
            name="Guía de buenas prácticas para la población infantil
            durante el estado de alarma"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day43;
